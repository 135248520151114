/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { CommonTranslationsContext } from 'layouts'
import useCollapse from 'react-collapsed'
import { Button } from 'theme-ui'
import serializers from './serializers'

const ReadMore = ({ blocks, notes, count }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const { readOn, readLess } = useContext(CommonTranslationsContext)
  return (
    <React.Fragment>
      <div className='css-grid'>
        <Button
          sx={{ gridColumnStart: ['1', null, null, '2'], justifySelf: 'start', mt: 3, mb: 3 }}
          {...getToggleProps()}>{ isExpanded ? readLess?.localized : readOn?.localized }</Button>
      </div>
      <div {...getCollapseProps()}>
        <BlockContent className='css-grid' blocks={blocks} serializers={serializers(blocks, notes, count)} />
      </div>
    </React.Fragment>
  )
}

export default ReadMore
