/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'

const SectionLink = ({ node, children, className }) => {
  return (
    <div id={node.sectionId} className={className}>{children}</div>
  )
}

export default SectionLink
