/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState } from 'react'
import { isValid, format } from 'date-fns'
import { enGB, el, de } from 'date-fns/locale'
import { Box, Heading, Text } from 'theme-ui'
import PopOut from 'components/PopOut'
import BlockText from 'components/Modules/BlockText'
import PersonWithRole from 'components/PersonWithRole'
import getResponsiveStyles from 'lib/getResponsiveStyles'

const locales = { enGB, el, de }
function formatLocale(date, formatStr = 'PP', locale) {
  return format(date, formatStr, {
    locale: locales[locale]
  })
}

const Event = ({locale, title, subject, date, description, listTitle, people}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }
  const heading = subject ? `${title}\n${subject}` : title
  const formattedDate = isValid(new Date(date)) && formatLocale(new Date(date), `EEEE',' MMMM d`, locale)

  return (
    <li className='css-grid' sx={{ listStyle: `none`, py: 3 }}>
      <div sx={{ gridColumn: ['2/5', null, '2/7', '3/9', '3/8', '3/6'] }}>
        <span sx={{ variant: 'text.fms'}}>{formattedDate}</span>
        <Heading
          as='h4'
          sx={{...(getResponsiveStyles('variant', ['text.frl', null, 'text.frxl']))}}
        >
          <span sx={{ variant: description && 'links.text' }} onClick={description && toggleIsOpen}>{heading}</span>
        </Heading>
      </div>
      <PopOut title={title} open={isOpen} onClose={() => setIsOpen(false)} padding='2' bg='text' color='background'>
        <Text variant='frs'><BlockText blocks={description} /></Text>
      </PopOut>
      <div sx={{ gridColumn: ['1/5', null, '1/7', '2/9', '2/7', '2/6'] }}>
        {listTitle && <Text variant='frl' sx={{ textTransform: 'uppercase' }}>{listTitle}</Text>}
        {
          people && <ul>
          {
            people.map((item, index) => {
              if (item === null) return <div/>
              return (
                <PersonWithRole
                  key={item._key || item._id}
                  {...item}
                  count={people.length}
                  index={index}
                />
              )
            })
          }
         </ul>
        }
      </div>
    </li>
  )

}

const EventsModule = ({ node = {}, locale }) => <ul>{(node.events || []).map(({_key, ...rest}) => <Event key={_key} locale={locale} {...rest} />)}</ul>

export default EventsModule
