import React, { useEffect, useRef } from 'react'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
} from '@mattjennings/react-modal'
import { Flex, Text, Button, Box } from 'theme-ui'
import { useMediaQuery } from 'react-responsive'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { CornerV2 as Corner } from 'components/Corner'

function PopOut(props) {

  const scrollRef = useRef()

  const isTablet = useMediaQuery({ query: '(max-width: 1280px)' })
  // set scroll lock class on body (or html in this case)
  useEffect(() => {
    if (scrollRef?.current) {
      if (props.open) {
        // console.log('PopOut: Disable body scroll', scrollRef.current)
        disableBodyScroll(scrollRef.current)
      } else {
        // console.log('PopOut: Enable body scroll', scrollRef.current)
        enableBodyScroll(scrollRef.current)
      }
    }
    /* return () => {
      console.log("PopOut: useEffect return", scrollRef)
      return clearAllBodyScrollLocks()
    }*/
  }, [props.open])

  return (
    <Box sx={{
      display: 'inline',
      '> div': {
        display: 'grid',
        gridTemplateColumns: ['repeat(4, 1fr)', null, 'repeat(6, 1fr)', 'repeat(12, 1fr)'],
        gap: 0,
        pl: '10px',
        zIndex: 1001
      }
    }}>
      <Modal
        {...props}
        py='2'
        pl='2'
        color='background'
        animations={{
          default: {
            enter: {
              scale: 1,
              x: '0',
              transition: {
                duration: 0.3,
                ease: 'easeInOut',
              },
            },
            exit: {
              scale: 0.9,
              x: '100%',
              transition: {
                duration: 0.3,
                ease: 'easeInOut',
              },
            },
          },
        }}
      >
        {({ onClose }) => (
          <>
            <Flex direction='row'>
              <Box sx={{
                position: 'relative',
                bg: 'transparent',
                variant: 'text.fms',
                pr: 1,
                pb: '2px',
                color: 'text',
                maxWidth: ['50vw', null, '25vw', '16.66vw']
              }}
              >
                <Corner bottom='0' right='0' inverse />
                {props.title}
              </Box>
              <Box sx={{
                bg: 'black',
                flexGrow: 1,
                borderTopLeftRadius: 'default',
              }}
              />
            </Flex>
            <Corner inverse bottom='100%' right='0' />
            <Box
              // use instead of ModalContent for easy refs
              ref={scrollRef}
              pt={4}
              pb={2}
              pl={2}
              bg={'black'}
              sx={{
                borderTopLeftRadius: 'large',
                borderBottomLeftRadius: 'default',
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch', // this has no effect (?)
                '.gatsby-image-wrapper': {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0
                }
              }}
            >
              {props.children}
            </Box>
            <ModalFooter>
              <Box onClick={onClose} sx={{variant: 'links.inverted', fontSize: '18px', my: 0, mx: 3 }}>×</Box>
            </ModalFooter>
            <Corner top='100%' right='0' inverse large />
          </>
        )}
      </Modal>
    </Box>
  )
}

export default PopOut
