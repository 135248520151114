/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState, useEffect } from "react"
import BlockContent from "@sanity/block-content-to-react"
import { Text, Box } from 'theme-ui'
import ReadMore from 'components/Modules/ReadMore'
import serializers from './serializers'

const RichTextModule = ({ node: { intro = [], content = [], notes = {}, richText = [] } }) => {
  const blocks = intro.length > 0 ? intro : richText
  return (
    <React.Fragment>
      <BlockContent
        renderContainerOnSingleChild={true}
        className='css-col-grid richText'
        blocks={blocks}
        serializers={serializers(blocks, intro.length > 0 ? notes.intro : notes.richText)}
      />
      { content.length > 0 && <ReadMore blocks={content} notes={notes.content} count={notes.count} /> }
    </React.Fragment>
  )
}

export default RichTextModule
