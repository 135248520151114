/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState } from 'react'
import get from 'lodash.get'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { Text, Box } from 'theme-ui'
import PopOut from 'components/PopOut'
import Figure from 'components/Modules/Figure'
import BlockText from 'components/Modules/BlockText'
import { getGatsbyImageData } from 'lib/getGatsbyImageProps'
import useResponsiveConfig from 'lib/useResponsiveConfig'

const PersonWithRole = ({ _key, _id, name, role, bio, image, count, index }) => {
  // console.log('PersonWithRole image: ', image)
  const [isOpen, setIsOpen] = useState(false)

  const imageIsEmpty = image && Object.entries(image).length === 0
  const hasBio = !imageIsEmpty && bio
  const toggleIsOpen = () => {
    hasBio && setIsOpen(!isOpen)
  }

  return (
    <li
      key={_key || _id}
      sx={{
        listStyle: 'none',
        display: 'inline-block',
        // variant: 'text.frxl'
        variant: useResponsiveConfig(['frm', null, null, 'frl'])
      }}
    >
      {
        name &&
        <React.Fragment>
          <span sx={{ variant: hasBio && 'links.text'}} onClick={toggleIsOpen}>
            {`${name}`}</span>
          {role && <Text sx={{ verticalAlign: 'middle', textTransform: 'lowercase' }} pl='1' pr='2' variant='fms'>{role}</Text>}
        </React.Fragment>
      }
      <PopOut open={isOpen} onClose={() => setIsOpen(false)} title={name}>
        { image && image.asset && <figure sx={{ ['picture, img']: { borderTopRightRadius: 0, borderBottomRightRadius: 0 }}}>
          <GatsbyImage
            image={getGatsbyImageData(image, { width: 460, placeholder: 'dominantColor' })}
            alt={image.alt}
          />
          {/*<figcaption
            css={{
              marginTop: `2rem`,
            }}
            dangerouslySetInnerHTML={{ __html: image.caption }}
          />*/}
        </figure> }
        {bio && <Box pt='1' pr='2'><Text variant='frs'><BlockText blocks={bio} /></Text></Box>}
      </PopOut>
    </li>
  )
}

export default PersonWithRole
