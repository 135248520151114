/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { format, isSameYear } from 'date-fns'
import { Grid, Text, Box } from 'theme-ui'
import { Flipper, Flipped } from 'react-flip-toolkit'
import getResponsiveStyles from 'lib/getResponsiveStyles'

function formatDates(startedAt, endedAt, yearOnly, isOngoing) {
  const datesAreSameYear = isSameYear(new Date(startedAt), new Date(endedAt))
  switch (true) {
    case !startedAt:
      return yearOnly ? `${format(new Date(endedAt), `yyyy`)}` : `${format(new Date(endedAt), `dd.MM.yyyy`)}`
    case isOngoing:
      return yearOnly ? `${format(new Date(startedAt), `yyyy`)} —\nPresent` : `${format(new Date(startedAt), `dd.MM.yyyy`)} —\nPresent`
    case !endedAt: // isOngoing is false, so it's a single occurance
      return `${format(new Date(startedAt), `dd.MM.yyyy`)}`
    // there is a startedAt & endedAt
    case datesAreSameYear:
      return `${format(new Date(startedAt), `dd.MM`)} —\n${format(new Date(endedAt), `dd.MM.yyyy`)}`
    case (yearOnly && !datesAreSameYear):
      return `${format(new Date(startedAt), `yyyy`)} —\n${format(new Date(endedAt), `yyyy`)}`
    default: // yearOnly and datesAreSameYear are false
      return `${format(new Date(startedAt), `dd.MM.yyyy`)} —\n${format(new Date(endedAt), `dd.MM.yyyy`)}`
  }
}

const Subheader = ({
  icon,
  isScrolled,
  startedAt = "",
  endedAt = "",
  yearOnly = false,
  isOngoing = false,
  location,
  subHeaderRef
}) => {

  return <Text
  ref={subHeaderRef}
  as='div'
  className='css-grid'
  sx={{
    mt: 4,
    mb: [4, null, 8],
    ...(getResponsiveStyles('variant', ['text.frm', null, 'text.frxl']))
  }}
>
    <Box
      sx={{
        gridColumn: ['span 2'],
        gridRow: ['2/2', null, null, 1],
      }}
    >
      <Box
        sx={{
          animation: 'fadeIn 0.4s forwards',
          animationDelay: '2800ms',
          opacity: 0,
          width: 'calc(50% - 10px)'
        }}
      ><img width='100%' height='auto' src={icon} /></Box>
    </Box>
  { (startedAt || endedAt) &&
    <Box sx={{ gridColumn: ['span 2', null, null, 'span 3'], whiteSpace: 'pre-wrap' }} >
      {formatDates(startedAt, endedAt, yearOnly, isOngoing)}
    </Box>
  }
  { location &&
    <Box sx={{ gridColumn: ['span 2', null, null, 'span 3'] }} >
      {location}
    </Box>
   }
</Text>
}


export default Subheader
