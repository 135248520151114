import GraphQLErrorList from 'components/graphql-error-list'
import Initiative from 'components/Initiative'
import SEO from 'components/seo'
import { graphql } from 'gatsby'
import React from 'react'
import { Container } from 'theme-ui'

export const fragment = graphql`
  fragment ImageWithHotspot on SanityFigure {
    alt
    _rawBlockCaption
    crop {
      bottom
      left
      top
      right
    }
    hotspot {
      y
      x
      height
      width
    }
    asset {
      id
      url
      metadata {
        dimensions {
          height
          width
          aspectRatio
        }
        palette {
          dominant {
            background
          }
        }
      }
    }
  }
`

export const query = graphql`
  query InitiativeTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...LocalizedSettings
    }
    initiative: sanityInitiative(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      publishedAt
      categories {
        _id
        title {
          localized
          en_GB
        }
        description {
          en_GB
          localized
        }
        invertedIcon {
          asset {
            altText
            url
          }
        }
        icon {
          asset {
            altText
            url
          }
        }
      }
      location
      startedAt
      endedAt
      yearOnly
      isOngoing
      mainImage {
        ...ImageWithHotspot
      }
      modulesWithSectionIds
      i18nRelatedInitiatives {
        title
        slug {
          current
        }
      }
      relatedInitiatives {
        id
        title
        slug {
          current
        }
        i18n_path
        i18n_lang
        size
        categories {
          _id
          title {
            localized
            en_GB
          }
          description {
            localized
          }
          invertedIcon {
            asset {
              altText
              url
            }
          }
        }
        mainImage {
          ...ImageWithHotspot
        }
      }
    }
  }
`

const InitiativeTemplate = ({ data, path, errors, pageContext, showMenuHandler }) => {
  const initiative = {
    path,
    showMenuHandler,
    ...pageContext,
    ...data.initiative,
    // _rawModules: data.initiative._rawModules,
    relatedInitiatives:
      data.initiative.relatedInitiatives.map((item, index) => {
        return {
          ...item,
          title: data.initiative.i18nRelatedInitiatives[index]?.title,
          i18n_path: data.initiative.i18nRelatedInitiatives[index]?.slug.current,
        }
      }) || [],
  }
  return (
    <React.Fragment>
      {errors && <SEO title="GraphQL Error" />}
      {initiative && (
        <SEO
          title={initiative.title || 'Untitled'}
          description={data?.site?.description?.localized || ''}
          image={initiative.mainImage?.asset?.url}
        />
      )}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {initiative && <Initiative {...initiative} />}
    </React.Fragment>
  )
}

export default InitiativeTemplate
