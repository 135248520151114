import BlockContent from 'components/block-content'
import Header from 'components/Header'
import Related from 'components/Related'
import SectionLinks from 'components/SectionLinks'
import React, { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Container } from 'theme-ui'

function Initiative(props) {
  const {
    sectionLinks = [],
    modulesWithSectionIds,
    path,
    showMenuHandler,
    relatedInitiatives = [],
    locale,
  } = props

  const [isScrolled, setScrolled] = useState(false)

  const scrollToContent = () => {
    const position = window.pageYOffset
    if (position < window.innerHeight) {
      window.scrollTo({
        top: Math.floor(window.innerHeight * 0.84),
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  // only on initial render
  useEffect(() => {
    showMenuHandler(false)
    setTimeout(() => {
      setScrolled(true)
      // here I can call a function
      showMenuHandler(true)
    }, 2600)
  }, [])

  return (
    <Container as="article" sx={{ padding: '10px' }}>
      <Header {...props} isScrolled={isScrolled} onClick={scrollToContent} path={path} />
      {modulesWithSectionIds && (
        <BlockContent locale={locale} blocks={modulesWithSectionIds || []} />
      )}
      {relatedInitiatives.length > 0 && (
        <Related nodes={relatedInitiatives} />
      )}
    </Container>
  )
}

export default Initiative
