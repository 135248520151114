/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState } from 'react'
import get from 'lodash.get'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { Text, Box } from 'theme-ui'
import Figure from 'components/Modules/Figure'
import BlockText from 'components/Modules/BlockText'
import PopOut from 'components/PopOut'
import { getGatsbyImageData } from 'lib/getGatsbyImageProps'


function formatNames(name, index, count) {
  switch (true) {
    case index === count - 1:
      return name
    default:
      return <React.Fragment>{name},&nbsp;</React.Fragment>
  }
}

const Person = ({ _key, _id, name, role, bio, image = {}, count, index }) => {
  const [isOpen, setIsOpen] = useState(false)
  // console.log('Person image: ', image)

  const imageIsEmpty = image && Object.entries(image).length === 0
  const hasBio = !imageIsEmpty && bio
  const toggleIsOpen = () => {
    hasBio && setIsOpen(!isOpen)
  }

  return (
    <React.Fragment
      key={_key || _id}
    >
      {name && <Text sx={{ display: 'inline', variant: hasBio && 'links.text'}} onClick={toggleIsOpen}>{formatNames(name, index, count)}</Text>}
      <PopOut open={isOpen} onClose={() => setIsOpen(false)} title={name}>
        { image && image.asset && <figure sx={{ ['picture, img']: { borderTopRightRadius: 0, borderBottomRightRadius: 0 }}}>
          <GatsbyImage
            image={getGatsbyImageData(image, { width: 920, placeholder: 'dominantColor' })}
            alt={image.alt}
          />
        </figure> }
        {bio && <Box pt='1' pr='2'><Text variant='frs'><BlockText blocks={bio} /></Text></Box>}
      </PopOut>
    </React.Fragment>
  )
}

export default Person
