import React from "react"
import BaseBlockContent from "@sanity/block-content-to-react"
import SimpleFootnotes from 'components/Modules/SimpleFootnotes'
import serializers from './serializers'

const BlockText = ({ blocks }) => {
  const notes = blocks && blocks
    // filter out everything that's not a text block
    .filter(({ _type }) => _type === 'block')
    // make an array of the mark definitions of those blocks
    .reduce((acc, curr) => {
      return [...acc, ...curr.markDefs];
    }, [])
    // find all the footnote mark definitions
    .filter(({ _type }) => _type === 'footnote') || []

  return (
    <React.Fragment>
      <BaseBlockContent renderContainerOnSingleChild={true} className='block-text' blocks={blocks} serializers={serializers(blocks, notes, 0)} />
      <SimpleFootnotes blocks={blocks} />
    </React.Fragment>
  )
}

export default BlockText
