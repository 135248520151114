/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useRef, useEffect } from 'react'
import Scrollspy from 'components/Scrollspy'

const SectionLinks = ({ isScrolled, isSticky, items, path, sectionLinksTop }) => {
  const spy = useRef()

  useEffect(() => {
    spy.current.getElements()
  }, [items, path])

  const handleClick = (event) => {
    event.preventDefault()
    const anchorTarget = document.getElementById(event.target.hash.replace("#", ""))
    if (anchorTarget) anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  const handleActiveChange = (id) => {}

  return (
    <Scrollspy
      ref={spy}
      items={items.map(({ _key }) => _key)}
      activeClass={'current'}
      options={{
        rootMargin: '-20% 0px -80%',
        threshold: 0,
      }}
      handleActiveChange={handleActiveChange}
    >
      <aside
        sx={{
          transition: 'opacity .25s linear',
          opacity: isScrolled ? 1 : 0,
          position: isSticky ? 'fixed' : 'absolute',
          top: isSticky ? `50%` : `${sectionLinksTop}px`,
          width: ['25%', null, '33.33%', '8.333%'],
          zIndex: 887,
          whiteSpace: 'nowrap'
        }}
      >
        <ul sx={{ p: 0, variant: 'text.frs' }}>
          {
            items.map(({ _key, title }) => {
              return (
                <li
                  key={_key}
                  sx={{
                    pb: '2px',
                    listStyle: `none`,
                  }}
                >
                  <a href={`${path}#${_key}`} onClick={handleClick}>{title}</a>
                </li>
              )
            })
          }
        </ul>
      </aside>
    </Scrollspy>
  )
}

export default SectionLinks
