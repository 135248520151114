import React from 'react'
import BaseBlockContent from '@sanity/block-content-to-react'
import { Box, Heading } from 'theme-ui'
import Figure from 'components/Modules/Figure'
import Sl from 'components/Modules/SectionLink'
import RichTextModule from 'components/Modules/RichTextModule'
import ImagesModule from 'components/Modules/ImagesModule'
import GalleryModule from 'components/Modules/GalleryModule'
import YoutubeModule from 'components/Modules/YoutubeModule'
import EventsModule from 'components/Modules/EventsModule'
import PeopleModule from 'components/Modules/PeopleModule'
import ListModule from 'components/Modules/ListModule'
import SponsorsModule from 'components/Modules/SponsorsModule'
import clientConfig from '../../client-config'

const serializers = (locale) => ({
  types: {
    richTextModule: (props) => <Sl {...props}><RichTextModule {...props} /></Sl>,
    imagesModule: (props) => <Sl {...props} className='css-grid'><ImagesModule {...props} /></Sl>,
    galleryModule: (props) => {
      return <Sl className='css-grid' {...props}>{props.node.images && props.node.images.length > 2 ? <GalleryModule {...props} /> : <ImagesModule {...props} />}</Sl>
    },
    youtubeModule: (props) => <Sl className='css-grid' {...props}><YoutubeModule {...props} /></Sl>,
    eventsModule: (props) => <Sl {...props}><EventsModule locale={locale} {...props} /></Sl>,
    peopleModule: (props) => <Sl className='css-grid' {...props}><PeopleModule {...props} /></Sl>,
    listModule: (props) => (
      <Sl className='css-grid' {...props}>
        <Box sx={{ gridColumn: ['1/5', null, '2/6', '3/10', '3/8', '3/7'], mt: 2 }}>
          <Heading as='h4' sx={{ variant: 'text.frm', mb: 0 }}>{props.node.title}</Heading>
          <ListModule {...props} />
        </Box>
      </Sl>
    ),
    sponsorsModule: (props) => <Sl {...props}><SponsorsModule {...props} /></Sl>,
  }
})

const BlockContent = ({ blocks, locale }) => {
  return <BaseBlockContent
    blocks={blocks}
    serializers={serializers(locale)}
    {...clientConfig.sanity}
  />
};

export default BlockContent
