/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import Person from 'components/Person'

const PeopleModule = ({ node = {} }) => {
  // console.log('PeopleModule: ', node)
  return (
    <div sx={{ gridColumn: ['1/5', null, '1/7', '2/9', '2/7', '2/6'] }}>
      <h4>{node.title}</h4>
      <div sx={{ p: 0, mb: 5, variant: 'text.frxl' }}>
        {
          (node.items || []).map((item, index) => {
            if (item === null) return <div/>
            return (
              // item can be null
              <Person
                key={item._key || item._id}
                {...item}
                count={node.items.length}
                index={index}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default PeopleModule
