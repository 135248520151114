import { Component } from 'react'
import PropTypes from 'prop-types'

export default class Scrollspy extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    /** Id list of target contents. */
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** Class name to toggle when one of the items enters the viewport. */
    activeClass: PropTypes.string.isRequired,
    /** Intersection observer options */
    options: PropTypes.shape({
      root: PropTypes.node,
      rootMargin: PropTypes.string.isRequired,
      threshold: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    }).isRequired,
  }

  static defaultProps = {
    activeClass: '',
    options: {
      rootMargin: '0px 0px 0px 0px',
      threshold: 0,
    },
  }

  state = {}

  componentDidMount() {
    // seems like depending on how quickly the page renders on mobile (only)
    // the mutationObserver doesn't catch any changes!
    // we know that our elements will only change on page change, so let's skip it
    // this.createMutationObserver()
    this.getElements()
  }

  getElements = () => {
    const { items } = this.props
    const { pathname } = window.location

    this.setState(
      {
        elementsToSpy: items.map(item => document.getElementById(item)).filter(item => item),
        navItems: items
          .map(
            item => {
              return document.querySelector(`[href="${pathname}#${item}"]`) ||
              document.querySelector(`[href="${pathname}/#${item}"]`)
            }
          )
          .filter(item => item),
      },
      () => {
        this.createIntersectionObserver()
      }
    )
  }

  createMutationObserver = () => {
    new MutationObserver((mutations, observer) => {
      this.getElements()
      observer.disconnect()
    }).observe(document.body, {
      childList: true,
      subtree: true,
    })
  }

  createIntersectionObserver = () => {
    const { elementsToSpy, navItems } = this.state
    const { options } = this.props


    const observer = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting, intersectionRatio, target }) => {
        if (isIntersecting) {
          this.removeActiveClass()
          this.addActiveClass(target.id, navItems.find(navItem => navItem.href.includes(target.id)))
        }
      })
    }, options)

    elementsToSpy.forEach(elem => {
      observer.observe(elem)
    })
  }

  addActiveClass = (id, el) => {
    const { activeClass, handleActiveChange } = this.props
    if (handleActiveChange) handleActiveChange(id)
    el && activeClass !== "" && el.classList.add(activeClass)
  }

  removeActiveClass = () => {
    const { navItems } = this.state
    const { activeClass } = this.props

    if (navItems) {
      const active = navItems.find(navItem => navItem.classList.contains(activeClass))
      active && active.classList.remove(activeClass)
    }
  }

  render() {
    return this.props.children
  }
}
