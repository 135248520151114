/** @jsx jsx */
import { jsx, Heading, Text } from 'theme-ui'
import React from 'react'
import get from 'lodash.get'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'lib/getGatsbyImageProps'
import getResponsiveStyles from 'lib/getResponsiveStyles'

function sizeToWidth(size) {
  // 75%, (M) 82% & (L) 100% of their max-width in their column
  switch (true) {
    case size === 'narrow':
      return { width: '70%' }
    case size === 'average':
      return { width: '82%' }
    default:
      return { width: '100%' }
  }
}

const SponsorsModule = ({ node = {} }) => {
  // console.log('SponsorsModule: ', node)
  return (
    <div className='css-grid'>
      <div sx={{ gridColumn: ['1/5', null, '2/7', '3/10', '3/8', '3/7'] }}>
        <Heading
          as='h4'
          sx={{
            fontFamily: 'body',
            ...(getResponsiveStyles('variant', ['text.frm', null, null, null, null, 'text.fms'])),
            mb: 0
          }}
        >{node.title}</Heading>
        <Text
          as='div'
          sx={{
            ...(getResponsiveStyles('variant', ['text.frm', null, null, null, 'text.frl'])),
            mb: 7,
            display: 'grid',
            gridTemplateColumns: ['repeat(4, 1fr)', null, 'repeat(6, 1fr)', 'repeat(6, 1fr)'],
            gap: '10px'
          }}
        >
          {
            (node.items || []).map((item, index) => {
              const image = (item._type === 'sponsor') ? item.logo : item.asset
              const altText = (item._type === 'sponsor') ? get(item, 'logo.asset.altText', '') : get(item, 'asset.altText', '')
              return (
                <div key={item._key} sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div sx={{...sizeToWidth(item.size)}}>
                    <GatsbyImage
                      image={getGatsbyImageData(image, { width: 300 })}
                      alt={altText}
                    />
                  </div>
                </div>
              )
            })
          }
        </Text>
      </div>
    </div>
  )
}

export default SponsorsModule
