/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState, useEffect, useRef } from 'react'
import { useScrollPosition } from 'lib/useScrollPosition'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import get from 'lodash.get'
import BaseBlockContent from '@sanity/block-content-to-react'
import { Text, Box, Heading } from 'theme-ui'
import { Flipper, Flipped } from 'react-flip-toolkit'
import Corner from 'components/Corner'
import { getGatsbyImageData } from 'lib/getGatsbyImageProps'
import Subheader from 'components/Subheader'
import SectionLinks from 'components/SectionLinks'
import getResponsiveStyles from 'lib/getResponsiveStyles'

const Category = ({isScrolled, icon, category}) =>
  <Box
    className='category'
    sx={{
      opacity: isScrolled ? 0 : 1,
      transition: 'opacity .25s linear',
      width: ['33.3333%', null, '16.6666%', '8.33333%'],
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 200,
      'img': {
        display: 'block',
        borderBottomRightRadius: 'large',
      }
    }}
  >
  <img width='100%' height='auto' src={icon} />
  <Box sx={{position: 'absolute', top: `0`, left: `100%`}}><Corner large top left/></Box>
  <Box sx={{position: 'absolute', top: `100%`, left: `0`}}><Corner large top left/></Box>
</Box>

const FullscreenImage = ({ mainImage, isScrolled }) => {
  return (
      <Box
        as='figure'
        sx={{
          animation: 'fadeIn 1200ms forwards',
          position: 'absolute',
          zIndex: -1,
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          ml: 0,
          '.gatsby-image-wrapper': {
            height: '100%',
            borderRadius: 'large',
            borderTopLeftRadius: 'large'
          }
        }}
      >
      {mainImage && mainImage.asset && (
        <GatsbyImage
          image={getGatsbyImageData(mainImage, { layout: 'fullWidth', hotspot: mainImage.hotspot, width: 2520, height: 1418, placeholder: 'dominantColor' })}
          alt={mainImage.alt || 'hero image'}
        />
      )}
      </Box>
  )
}

const Image = ({ mainImage, isScrolled }) => {
  return (
      <Box
        as='figure'
        sx={{
          animation: 'fadeIn 1200ms forwards',
          // zIndex: -1,
          // top: 0,
          // left: 0,
          // position: 'relative',
          // height: 'auto',
          // width: 'calc(100% + 10pt)',
          ml: '-10px',
          opacity: 0,
          // position: isScrolled ? 'relative' : 'absolute',
          // height: isScrolled ? 'auto' : '100%',
          // width: isScrolled ? 'calc(100% + 10pt)' : '100%',
          // ml: isScrolled ? '-10px' : 0,
          '.gatsby-image-wrapper': {
            height: '100%',
            borderRadius: 'large',
            borderTopLeftRadius: isScrolled ? 'large' : 0,
          }
        }}
      >
        {mainImage && mainImage.asset && (
        <GatsbyImage
          image={getGatsbyImageData(mainImage, { layout: 'fullWidth', hotspot: mainImage.hotspot, width: 2520, height: 1418, placeholder: 'dominantColor' })}
          alt={mainImage.alt || 'hero image'}
        />
       )}
      </Box>
  )
}

const Header = ({
  title,
  categories,
  mainImage = {},
  isScrolled,
  sectionLinks,
  path,
  onClick,
  ...subheader
}) => {

  const [isSticky, setSticky] = useState(false)

  const sectionLinksRef = useRef()
  const subHeaderRef = useRef()
  const [sectionLinksTop, setSectionLinksTop] = useState(0)

  useEffect(() => {
    // @TODO: recalculate on window resize
    if (subHeaderRef) {
      const rect = subHeaderRef.current.getBoundingClientRect()
      const contentStart = window.scrollY + rect.top + rect.height + 100 + 25
      setSectionLinksTop(contentStart)
    }
  }, [])

  useScrollPosition(({ prevPos, currPos }) => {
    // we have a magic number here that I am using to align it by eye, this doesn't feel right
    if (currPos.y > Math.floor(sectionLinksTop + 100 + (window.innerHeight * .50))) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }, [])


  const invertedIcon = get(categories[0], 'invertedIcon.asset.url')
  const icon = get(categories[0], 'icon.asset.url')
  const category = get(categories[0], 'title.en_GB')

  return (
    <Flipper flipKey={isScrolled} spring={{ stiffness: 400, damping: 65 }}>
      <Box onClick={onClick} sx={{
        cursor: 'pointer',
        // https://css-tricks.com/positioning-overlay-content-with-css-grid/
        // max-height: clamp(450px, 50vh, 600px); interesting?
        position: 'relative',
        height: '84vh',
        alignContent: 'end'
      }}
      className='css-grid grid-end'
      >
        <Category isScrolled={isScrolled} icon={invertedIcon} category={category} />
        <Flipped stagger='text' flipId='heading'>
          <Heading
            as='h1'
            sx={{
              ...(getResponsiveStyles('variant', ['text.frxl', null, 'text.frxxxl', null, null, 'text.frxxxxl'])),
              gridColumn: ['1/4', null, '1/7',  '1/11'],
              position: isScrolled ? 'absolute' : 'relative',
              bottom: isScrolled ? '100%' : 0,
              transform: isScrolled ? 'translateY(calc(100% + 10pt))' : 'translateY(0)',
              pb: [0, null, 6],
              pl: 1,
              margin: 0,
              transition: 'color .25s linear',
              color: isScrolled ? 'black' : 'white'
            }}
          >{title}</Heading>
        </Flipped>
        <Box sx={{ gridColumn: ['2/5', null, '5/7', '11/13'] }}>
          <Flipped flipId='headerImage' opacity>
            {isScrolled ? <Image mainImage={mainImage} isScrolled={isScrolled}/> : <FullscreenImage mainImage={mainImage} isScrolled={isScrolled}/>}
          </Flipped>
          {mainImage && (mainImage._rawBlockCaption || mainImage.caption) &&
            <figcaption sx={{
              position: 'relative',
              zIndex: '100',
              pt: 1,
              pl: 1,
              width: 'calc(100% + 10pt)',
              ml: '-10px',
              borderTopLeftRadius: isScrolled ? 'none' : 'large',
              borderBottom: 'none',
              backgroundColor: 'white',
              '> p': { display: 'inline' },
              'span': { display: 'inline' }
            }}>
              <Box sx={{opacity: isScrolled ? 0 : 1, position: 'absolute', bottom: `100%`, right: `0`}}><Corner large/></Box>
              <Box sx={{opacity: isScrolled ? 0 : 1, position: 'absolute', bottom: `0`, right: `100%`}}><Corner large/></Box>
                {mainImage && (mainImage._rawBlockCaption || mainImage.caption) && <React.Fragment>
                  <span>↑ </span>{mainImage._rawBlockCaption ? <BaseBlockContent blocks={mainImage._rawBlockCaption} /> : mainImage.caption}
                </React.Fragment>}
            </figcaption>
          }
        </Box>
      </Box>
      <Subheader subHeaderRef={subHeaderRef} {...subheader} icon={icon} isScrolled={isScrolled} />
      <SectionLinks isScrolled={isScrolled} sectionLinksTop={sectionLinksTop} path={path} items={sectionLinks}  isSticky={isSticky} />
    </Flipper>
  )
}

export default Header
